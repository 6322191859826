import firebase from 'firebase'
// import * as firebaseui from 'firebaseui'
// import 'firebaseui/dist/firebaseui.css'


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyBtlBQH5REXOFu2q8mqPik0IEJdJBZWg9Y",
  authDomain: "mytestbaha.firebaseapp.com",
  databaseURL: "https://mytestbaha.firebaseio.com",
  projectId: "mytestbaha",
  storageBucket: "mytestbaha.appspot.com",
  messagingSenderId: "531521453146",
  appId: "1:531521453146:web:852d7dfa8c8fecfad4ec63",
  measurementId: "G-FT3R1647KP"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}


export {
  firebase
}