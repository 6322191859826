import React, { useState } from 'react'
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import firebase from 'firebase'
import "../../firebase";

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeMillis: 3000,
  minimumFetchIntervalMillis : 1
};
remoteConfig.defaultConfig = ({
  'welcome_message2': 'Welcome2',
  'welcome_message3': 'Welcome2',
  "resume" : true
});


let fetchFlag = false
function firebaseRemoteSettings(param) {

  async function showRemoteConfig() {
    const _resume = await remoteConfig.getValue('resume').asBoolean()
    //console.log("respenose", _resume)
    param(_resume)
    fetchFlag = true

  }

  if (!fetchFlag) {
    remoteConfig.fetchAndActivate()
      .then(() => {
        showRemoteConfig();
      })
      .catch((err) => {
        console.error(err);
      });
  }
}


export default function Greeting() {   
  //console.log("def", remoteConfig.defaultConfig.resume)
  const [resumeFLag, setResume] = useState(remoteConfig.defaultConfig.resume); 
  firebaseRemoteSettings(setResume)
  
  
  return (
    <Fade bottom duration={1000} distance="40px">
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          <div>
            <h1 className="greeting-text">
              {" "}
              {greeting.title}
              {" "}
              <span className="wave-emoji">{emoji("👋")}</span>
            </h1>
            <p className="greeting-text-p subTitle">{greeting.subTitle}</p>
            <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                { resumeFLag === true &&
                  <Button text="Resume" newTab={true} href={greeting.resumeLink} />
                }
                { resumeFLag !== true &&
                  <Button text="My Skills" href="#skills" />
                }
            </div>
          </div>
        </div>
        <div className="greeting-image-div">
          <img alt="saad sitting on table" src={require("../../assets/images/manOnTable.svg")}></img>
        </div>
      </div>
    </div>
    </Fade>
  );
}
